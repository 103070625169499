<template>
   <div class="min-h-100 d-flex flex-column h-100">
      <div class="row">
         <div class="col-xxl-12">
            <div class="card card-custom gutter-b">
               <div class="card-header border-0 pt-5 pb-5">
                  <h3 class="card-title align-items-start justify-content-center flex-column">
                     <span class="card-label font-weight-bolder">{{ $t('INVOICE.TITLE') }}</span>
                     <span class="text-muted mt-3 font-weight-bold font-size-sm"
                        >{{ $t('INVOICE.DESC', { number: invoices.length }) }} ({{ total }})</span
                     >
                  </h3>
                  <span class="font-weight-bolder text-info d-flex align-items-center w-25 text-right">
                     <p class="mx-2 my-2">Pour les factures antérieures au 01/01/2019, merci de contacter votre interlocuteur habituel.</p>
                  </span>
               </div>

               <div class="card-body p-0">
                  <Skeleton3 v-if="loading" />
                  <InvoiceTable v-else-if="invoices && invoices.length > 0" />
                  <div v-else-if="!loading && invoices && invoices.length === 0" class="col-xxl-12">
                     <div class="alert alert-info mx-auto w-50 text-center">
                        {{ $t('GENERAL.NO_DATA') }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { LIST_INVOICES } from '@/core/services/store/api-orion/invoice';
import Skeleton3 from '@/view/content/skeleton/Skeleton3';
import InvoiceTable from '@/view/pages/invoice/widget/Table';
export default {
   data: () => ({
      sortBy: 'date',
      isSortDirDesc: true,
      loading: true,
      sending: false,
      invoices: [],
      total: 0
   }),
   components: {
      Skeleton3,
      InvoiceTable
   },
   mounted() {
      this.resetError;
      this.setPageTitle();
      this.getInvoices();
   },
   methods: {
      formatData() {
         this.invoices.map((invoice) => {
            this.total += invoice.fac_total_ht;
         });
         this.total = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.total);
      },
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.INVOICES'));
      },
      async getInvoices() {
         const params = {
            start: '2019-01-01',
            year: ''
         };
         await this.$store
            .dispatch(LIST_INVOICES, { params })
            .then(() => {
               this.invoices = this.listInvoices;
               this.formatData();
            })
            .catch((error) => {
               if (error.status === 404) {
                  this.$router.push({ name: '404' });
               }
            });
      },
      linkGen(pageNum) {
         const query = {};
         Object.assign(query, this.$route.query);
         query.page = pageNum;
         return {
            name: this.$route.name,
            params: this.$route.params,
            query: query
         };
      }
   },
   watch: {
      invoices() {
         this.loading = false;
      }
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         listInvoices: 'listInvoices'
      }),
      ...mapState({
         error: (state) => state.invoice.error
      })
   }
};
</script>
